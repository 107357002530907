/* eslint-disable eqeqeq */
/* eslint-disable no-fallthrough */
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import GameService from "../../Services/GameService";
import { useAuth } from "../../Utils/Auth";
import { toast } from "react-toastify";
import { customErrorHandler } from "../../Utils/helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Update = ({ show, setShow, data, Update }) => {
  console.log("data = >>>>", data);
  const [newValue, setNewValue] = useState({
    gameName: "",
    Description: "",
    marketName: "",
    participants: "",
    timeSpan: "",
    startTime: "", // New state for start time
    endTime: "", // New state for end time

    runnerName: "",
    back: "",
    lay: "",
  });
  const [previousValues, setPreviousValues] = useState({ back: "", lay: "" }); // storing previous data for back and lay
  const [editedData, setEditedData] = useState({}); // Store edited data

  const auth = useAuth();
  const handleClose = () => {
    setNewValue((prevState) => ({
      ...prevState,
      gameName: "",
      Description: "",
      marketName: "",
      participants: "",
      timeSpan: "",
      startTime: "", // Reset start time
      endTime: "", // Reset end time
      runnerName: "",
      back: "",
      lay: "",
    }));
    setShow(false);
  };

  const handleStartDateTimeChange = (date) => {
    setNewValue((prevState) => ({
      ...prevState,
      startDateTime: date,
    }));
  };

  const handleEndDateTimeChange = (date) => {
    setNewValue((prevState) => ({
      ...prevState,
      endDateTime: date,
    }));
  };

  const handleNewValue = (event) => {
    const { name, value } = event.target;
    const updateKey = Update.toLowerCase(); // Ensure case insensitivity

    switch (updateKey) {
      case "game":
        setNewValue((prevState) => ({
          ...prevState,
          gameName: name === "gameName" ? value : prevState.gameName,
          Description: name === "description" ? value : prevState.Description,
        }));
        break;
      case "market":
        setNewValue((prevState) => ({
          ...prevState,
          marketName: name === "marketName" ? value : prevState.marketName,
          participants:
            name === "participants" ? value : prevState.participants,
          timeSpan: name === "timeSpan" ? value : prevState.timeSpan,
          startTime: name === "startTime" ? value : prevState.startTime,
          endTime: name === "endTime" ? value : prevState.endTime,
        }));
        break;
      case "runner":
        setNewValue((prevState) => ({
          ...prevState,
          runnerName: name === "runnerName" ? value : prevState.runnerName,
        }));
        break;
      case "rate":
        setNewValue((prevState) => ({
          ...prevState,
          back: name === "back" ? value : prevState.back,
          lay: name === "lay" ? value : prevState.lay,
        }));
        break;
      default:
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("=>>>>>", newValue);
    const updateKey = Update.toLowerCase();

    switch (updateKey) {
      case "game":
        const gameApiData = {
          gameId: data.gameId,
          gameName: newValue.gameName,
          description: newValue.Description,
        };
        GameService.gameUpdate(gameApiData, auth.user)
          .then((res) => {
            toast.success(res.data.message);
            handleClose();
          })
          .catch((err) => {
            toast.error(customErrorHandler(err));
          });

        break;
      case "market":
        const marketApiData = {
          marketId: data.marketId,
          marketName: newValue.marketName,
          participants: newValue.participants,
          // timeSpan: newValue.timeSpan,
          startTime: newValue.startTime, // Include start time in API data
          endTime: newValue.endTime, // Include end time in API data
        };
        GameService.marketUpdate(marketApiData, auth.user)
          .then((res) => {
            toast.success(res.data.message);
            handleClose();
          })
          .catch((err) => {
            toast.error(customErrorHandler(err));
          });
        console.log(newValue);
        break;
      case "runner":
        const runnerApiData = {
          runnerId: data.runnerId,
          runnerName: newValue.runnerName,
        };
        GameService.runnerUpdate(runnerApiData, auth.user)
          .then((res) => {
            toast.success(res.data.message);
            handleClose();
          })
          .catch((err) => {
            toast.error(customErrorHandler(err));
          });
        console.log(newValue);

        break;
      case "rate":
        const rateApiData = {
          runnerId: data.runnerId,
          back: newValue.back,
          lay: newValue.lay,
        };
        GameService.rateUpdate(rateApiData, auth.user)
          .then((res) => {
            toast.success(res.data.message);
            handleClose();
          })
          .catch((err) => {
            toast.error(customErrorHandler(err));
          });
        console.log(newValue);

      default:
    }
  };

  useEffect(() => {
    if (data && data.rates) {
      setPreviousValues({
        back: data.rates[0]?.Back || "",
        lay: data.rates[0]?.Lay || "",
      });
    }
  }, [data]);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {Update != "Rate" ? (
            <>
              {" "}
              Update {data?.gameName}
              {data?.marketName}
              {data?.runnerName}
            </>
          ) : (
            <>Update Rate</>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Update != "Rate" && (
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text w-100" id="basic-addon1">
                Give New Value Here &nbsp;&nbsp;&nbsp;
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Type here"
              name={
                Update === "Game"
                  ? "gameName"
                  : Update === "Market"
                  ? "marketName"
                  : Update === "Runner"
                  ? "runnerName"
                  : ""
              }
              // value={newValue.gameName}
              onChange={handleNewValue}
            />
          </div>
        )}

        {Update === "Game" && (
          <div className="form-group">
            <label for="exampleFormControlTextarea1">New Description:</label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              name="description"
              rows="3"
              value={newValue.Description}
              onChange={handleNewValue}
            />
          </div>
        )}

        {/* pending at Tom */}
        {/* {Update === "Market" && (
          <>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text w-100" id="basic-addon1">
                  Number of Participants
                </span>
              </div>
              <input
                type="number"
                className="form-control"
                placeholder="Type Here...."
                aria-label="participants"
                name="participants"
                onChange={handleNewValue}
              />
            </div>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text w-100" id="basic-addon1">
                  Time Span
                </span>
              </div>
              <select
                className="form-select"
                value={newValue.timeSpan || ""}
                name="timeSpan"
                onChange={handleNewValue}
              >
                <option selected>Select Time Span</option>
                <option value="15 Min">15 Min</option>
                <option value="30 Min">30 Min</option>
                <option value="45 Min">45 Min</option>
                <option value="60 Min">60 Min</option>
                <option value="90 Min">90 Min</option>
              </select>
            </div>

            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text w-100">
                  Start Date & Time
                </span>
              </div>
              {newValue.startTime}
             
              <DatePicker
                selected= {newValue.startTime ||  new Date()} 

                onChange={handleStartDateTimeChange}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="yyyy-MM-dd HH:mm"
                className="form-control"
                // placeholderText="Select Start Date & Time"
              />
            </div>

            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text w-100">End Date & Time</span>
              </div>
              <DatePicker
                selected={newValue.endTime || new Date(new Date().getTime() + 60000)}
                onChange={handleEndDateTimeChange}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="yyyy-MM-dd HH:mm"
                className="form-control"
                // placeholderText="Select End Date & Time"
              />
            </div>
          </>
        )} */}

        {Update === "Rate" && (
          <>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text w-100">Back</span>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder={previousValues.back}
                value={newValue.back}
                name="back"
                onChange={handleNewValue}
              />
            </div>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text w-100">Lay&nbsp;&nbsp;</span>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder={previousValues.lay}
                value={newValue.lay}
                name="lay"
                onChange={handleNewValue}
              />
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>

        <Button variant="primary" onClick={handleSubmit}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Update;
